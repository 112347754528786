<ng-container *ngIf="!item.hidden && item.roles.includes(ps.profile.role)">
        <!-- item.url -->
        <a
            class="nav-link"
            [ngClass]="item.classes"
            *ngIf="item.url && !item.externalUrl && !item.function"
            [routerLink]="[item.url]"
            [routerLinkActive]="['active', 'accent']"
            [routerLinkActiveOptions]="{ exact: item.exactMatch || false }"
            [target]="item.openInNewTab ? '_blank' : '_self'"
        >
            <ng-container *ngTemplateOutlet="itemContent"></ng-container>
        </a>

        <!-- item.externalUrl -->
        <a
            class="nav-link"
            [ngClass]="item.classes"
            *ngIf="item.url && item.externalUrl && !item.function"
            [href]="item.url"
            [target]="item.openInNewTab ? '_blank' : '_self'"
        >
            <ng-container *ngTemplateOutlet="itemContent"></ng-container>
        </a>

        <!-- item.function -->
        <span
            class="nav-link"
            [ngClass]="item.classes"
            *ngIf="!item.url && item.function"
            (click)="item.function()"
        >
            <ng-container *ngTemplateOutlet="itemContent"></ng-container>
        </span>

        <!-- item.url && item.function -->
        <a
            class="nav-link"
            [ngClass]="item.classes"
            *ngIf="item.url && !item.externalUrl && item.function"
            (click)="item.function()"
            [routerLink]="[item.url]"
            [routerLinkActive]="['active', 'accent']"
            [routerLinkActiveOptions]="{ exact: item.exactMatch || false }"
            [target]="item.openInNewTab ? '_blank' : '_self'"
        >
            <ng-container *ngTemplateOutlet="itemContent"></ng-container>
        </a>

        <!-- item.externalUrl && item.function -->
        <a
            class="nav-link"
            [ngClass]="item.classes"
            *ngIf="item.url && item.externalUrl && item.function"
            (click)="item.function()"
            [href]="item.url"
            [target]="item.openInNewTab ? '_blank' : '_self'"
        >
            <ng-container *ngTemplateOutlet="itemContent"></ng-container>
        </a>

        <ng-template #itemContent>
            <mat-icon class="nav-link-icon" *ngIf="item.icon">{{
                item.icon
            }}</mat-icon>
            <span class="nav-link-title">{{ item.title }}</span>
            <span
                class="nav-link-badge"
                *ngIf="item.badge"
                [ngStyle]="{
                    'background-color': item.badge.bg,
                    color: item.badge.fg
                }"
            >
                {{ item.badge.title }}
            </span>
        </ng-template>
</ng-container>
