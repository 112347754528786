import { User } from 'models';
import { Injectable } from '@angular/core';
import {
    Resolve,
    RouterStateSnapshot,
    ActivatedRouteSnapshot,
} from '@angular/router';
import { ProfileService } from '../services/profile.service';

@Injectable({
    providedIn: 'root',
})
export class ProfileResolverResolver implements Resolve<User> {
    constructor(private ps: ProfileService) {}
    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<User> {
        if (this.ps.profile) {
            return this.ps.profile;
        }
        return await this.ps.getProfile();
    }
}
