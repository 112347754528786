import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Auth } from '@aws-amplify/auth';

import { from } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LayoutService } from 'app/layout.service';
import { UserRole } from 'models';
import { DataStore } from 'aws-amplify';
import { ProfileService } from 'app/services/profile.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    public isLoggedIn: BehaviorSubject<boolean>;
    public username: string;
    public email: string;
    public password: string;
    public passwordConfirm: string;
    public oldPassword: string;
    public verificationCode: string;
    public userObject: any;

    public role: UserRole;
    public cognitoRole: UserRole;

    constructor(
        private router: Router,
        private layoutService: LayoutService,
        private ps: ProfileService
    ) {
        this.isLoggedIn = new BehaviorSubject<boolean>(false);

        this.resetUserParameters();
    }

    /**
     * Register new account
     * @returns {Observable<any>}
     */
    public registerNew(username, password, email): Observable<any> {
        return from(Auth.signUp(username, password, email));
    }

    /**
     * Register confirm account
     * @returns {Observable<any>}
     */
    public registerConfirm(username, verificationCode): Observable<any> {
        return from(Auth.confirmSignUp(username, verificationCode));
    }

    /**
     * Login
     * @returns {Observable<any>}
     */
    public signIn(email, password): Observable<any> {
        return from(Auth.signIn(email, password)).pipe(
            tap(() => {
                this.isLoggedIn.next(true);
                this.layoutService.setDefaultLayout();
            })
        );
    }

    /**
     * Sign out and redirect back to login
     */
    public signOut() {
        from(Auth.signOut()).subscribe(
            (result) => {
                this.isLoggedIn.next(false);
                this.ps.resetUser();
                localStorage.clear();
                this.router.navigateByUrl('/auth/login');
            },
            (error) => console.log(error)
        );
    }

    public signOutMobileUser() {
        from(Auth.signOut()).subscribe(
            (result) => {
                this.isLoggedIn.next(false);
                localStorage.clear();
                this.router.navigateByUrl('/mobile-users');
            },
            (error) => console.log(error)
        );
    }

    /**
     * Get authenticated state
     * @returns {Observable<boolean>}
     */
    public isAuthenticated(): Observable<boolean> {
        return from(Auth.currentAuthenticatedUser()).pipe(
            map((result) => {
                localStorage.setItem('user', result.username);
                this.username = result.username;
                this.cognitoRole =
                    result.signInUserSession.accessToken.payload[
                        'cognito:groups'
                    ][0];
                this.isLoggedIn.next(true);
                return true;
            }),
            catchError((error) => {
                DataStore.stop();
                this.isLoggedIn.next(false);
                return of(false);
            })
        );
    }

    /**
     * Get unauthenticated state
     * @returns {Observable<boolean>}
     */
    public isUnAuthenticated(): Observable<boolean> {
        return from(Auth.currentAuthenticatedUser()).pipe(
            map((result) => {
                // this.isLoggedIn.next(false);
                return false;
            }),
            catchError((error) => {
                DataStore.stop();
                // this.isLoggedIn.next(false);
                return of(true);
            })
        );
    }

    /**
     * Forgot password Init
     * @returns {Observable<any>}
     */
    public forgotPasswordInit(username): Observable<any> {
        return from(Auth.forgotPassword(username));
    }

    /**
     * Forgot password confirm
     * @returns {Observable<any>}
     */
    public forgotPasswordConfirm(
        username,
        verificationCode,
        password
    ): Observable<any> {
        return from(
            Auth.forgotPasswordSubmit(username, verificationCode, password)
        );
    }

    /**
     * Resend the registration verification code
     * @returns {Observable<any>}
     */
    public resendSignUpCode(username): Observable<any> {
        return from(Auth.resendSignUp(username));
    }

    /**
     * Reset user parameters
     */
    public resetUserParameters(): void {
        this.username = null;
        this.email = null;
        this.password = null;
        this.verificationCode = null;
    }

    public resetRequiredPassword(): Observable<any> {
        return from(
            Auth.completeNewPassword(
                this.userObject,
                this.password,
                this.userObject.challengeParam.requiredAttributes
            )
        );
    }

    public getUser(): Observable<any> {
        return from(Auth.currentAuthenticatedUser());
    }

    getUserRole() {
        return this.role;
    }

    async getUserRoleFromCognito() {
        return await Auth.currentAuthenticatedUser().then((data) =>
            console.log(
                data.signInUserSession.accessToken.payload['cognito:groups']
            )
        );
    }
}
