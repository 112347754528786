import { NgModule } from '@angular/core';

import { BhIfOnDomDirective } from '@bh/directives/bh-if-on-dom/bh-if-on-dom.directive';
import { BhInnerScrollDirective } from '@bh/directives/bh-inner-scroll/bh-inner-scroll.directive';
import { BhPerfectScrollbarDirective } from '@bh/directives/bh-perfect-scrollbar/bh-perfect-scrollbar.directive';
import { BhMatSidenavHelperDirective, BhMatSidenavTogglerDirective } from '@bh/directives/bh-mat-sidenav/bh-mat-sidenav.directive';

@NgModule({
    declarations: [
        BhIfOnDomDirective,
        BhInnerScrollDirective,
        BhMatSidenavHelperDirective,
        BhMatSidenavTogglerDirective,
        BhPerfectScrollbarDirective
    ],
    imports     : [],
    exports     : [
        BhIfOnDomDirective,
        BhInnerScrollDirective,
        BhMatSidenavHelperDirective,
        BhMatSidenavTogglerDirective,
        BhPerfectScrollbarDirective
    ]
})
export class BhDirectivesModule
{
}
