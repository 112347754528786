import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DataStore } from 'aws-amplify';
import { LocationType, User, UserRole, Warehouse } from 'models';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ProfileService {
    profile: User;
    isNational = false;
    isReadOnly = false;
    selectedWarehouseId = '';
    warehouse: Warehouse;
    userWarehouseId = '';
    userWarehouse: Warehouse;
    constructor(private router: Router) {}

    async getProfile() {
        const id = localStorage.getItem('user');
        const p = await DataStore.query(User, (u) => u.cognitoId('eq', id));
        this.profile = p[0];

        if (this.profile.role === UserRole.PORTALREADONLY) {
            this.isReadOnly = true;
        }

        this.setWarehouseId();

        return this.profile;
    }
    async setWarehouseId() {
        // Fix in place for users that might have experienced a bug where warehouseID was set as undefined
        if (localStorage.getItem('warehouseId') === 'undefined') {
            localStorage.removeItem('warehouseId');
        }

        if (localStorage.getItem('warehouseId')) {
            this.selectedWarehouseId = localStorage.getItem('warehouseId');
        } else {
            if (this.profile?.warehouseID) {
                localStorage.setItem('warehouseId', this.profile?.warehouseID);
                this.selectedWarehouseId = this.profile?.warehouseID;
            }
        }
        this.warehouse = await DataStore.query(
            Warehouse,
            this.selectedWarehouseId || this.profile.warehouseID
        );

        this.userWarehouseId = this.profile.warehouseID;
        this.userWarehouse = await DataStore.query(
            Warehouse,
            this.userWarehouseId
        );
        if (this.userWarehouse?.locationType === 'NATIONAL') {
            this.isNational = true;
        }
    }

    getwarehouseId() {
        if (!this.selectedWarehouseId) {
            this.selectedWarehouseId = localStorage.getItem('warehouseId');
        }
        return this.selectedWarehouseId;
    }

    isAdmin(): boolean {
        if (
            this.profile.role === UserRole.MOBILE ||
            this.profile.role === UserRole.REGIONALMANAGER
        ) {
            return false;
        }
        return true;
    }



    changeWarehouse(id: string) {
        localStorage.setItem('warehouseId', id);
        this.selectedWarehouseId = id;
        this.refreshPage();
    }

    async isSelectedWarehouseNational() {
        const warehouses = await DataStore.query(Warehouse);
        const selectedWarehouse = warehouses.find(
            (w) => w.id === this.selectedWarehouseId
        );
        if (selectedWarehouse.locationType === LocationType.NATIONAL) {
            return true;
        } else {
            return false;
        }
    }

    refreshPage() {
        let uri = this.router.url;
        this.router
            .navigateByUrl('/', { skipLocationChange: true })
            .then(() => this.router.navigateByUrl(uri));
    }

    async isNationalUser() {}

    resetUser() {
        this.profile = null;
        this.isNational = false;
        this.selectedWarehouseId = '';
        this.warehouse = null;
        this.userWarehouseId = '';
        this.userWarehouse = null;
    }
}
