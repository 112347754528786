import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ComsComponent } from "./coms.component";
import { ComsService } from "./coms.service";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";

@NgModule({
    declarations: [ComsComponent],
    imports: [
        CommonModule,
        MatSnackBarModule,
        MatIconModule,
        FlexLayoutModule,
        MatButtonModule,
    ],
    providers: [ComsService]
})
export class ComsModule {}
