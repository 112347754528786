import { NgModule } from "@angular/core";
import { MatDialogModule } from "@angular/material/dialog";

import { BhConfirmDialogComponent } from "@bh/components/confirm-dialog/confirm-dialog.component";
import { BaseModule } from "@bh/shared/base.module";

@NgModule({
    declarations: [BhConfirmDialogComponent],
    imports: [MatDialogModule, BaseModule]
})
export class BhConfirmDialogModule {}
