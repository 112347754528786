<div
    class="nav"
    [ngClass]="{
        horizontal: layout === 'horizontal',
        vertical: layout === 'vertical'
    }"
    *ngIf="ps.profile"
>
    <!-- Vertical Navigation Layout -->
    <ng-container *ngIf="layout === 'vertical'">
        <ng-container *ngFor="let item of navigation">
            <ng-container *ngIf="item.roles.includes(ps.profile.role)">
                <bh-nav-vertical-group
                    *ngIf="item.type == 'group'"
                    [item]="item"
                ></bh-nav-vertical-group>
                <bh-nav-vertical-collapsable
                    *ngIf="item.type == 'collapsable'"
                    [item]="item"
                ></bh-nav-vertical-collapsable>
                <bh-nav-vertical-item
                    *ngIf="item.type == 'item'"
                    [item]="item"
                ></bh-nav-vertical-item>
            </ng-container>
        </ng-container>
    </ng-container>
    <!-- / Vertical Navigation Layout -->

    <!-- Horizontal Navigation Layout -->
    <ng-container *ngIf="layout === 'horizontal'">
        <ng-container *ngFor="let item of navigation">
            <bh-nav-horizontal-collapsable
                *ngIf="item.type == 'group'"
                [item]="item"
            ></bh-nav-horizontal-collapsable>
            <bh-nav-horizontal-collapsable
                *ngIf="item.type == 'collapsable'"
                [item]="item"
            ></bh-nav-horizontal-collapsable>
            <bh-nav-horizontal-item
                *ngIf="item.type == 'item'"
                [item]="item"
            ></bh-nav-horizontal-item>
        </ng-container>
    </ng-container>
    <!-- / Horizontal Navigation Layout -->
</div>
