<div fxLayout="column" fxFlex bhPerfectScrollbar>
    <div class="p-12 mat-elevation-z4" fxLayoutAlign="space-between center">
        <div class="header">Notifications</div>

        <div>
            <button mat-icon-button (click)="close()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>

    <mat-divider cdkFocusRegionEnd></mat-divider>
    <div *ngIf='notifications.length > 0; else noNotifications'>
        <div *ngFor='let n of notifications' (click)='goTo(n)' class='p-4'>
            <div class='notification-wrapper'>
                <div class='notification-title'>
                    {{n.title}}
                </div>
                <div class='notification-message'>
                    {{n.message}}
                </div>
            </div>

        </div>
    </div>

    <ng-template #noNotifications>
        <div fxLayoutAlign="center center" class="p-24">
            <img src="assets/icons/alert.svg" alt="" />
        </div>
        <div class="text-center p-12">No notifications have been <br> received yet</div>
    </ng-template>
</div>