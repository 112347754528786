import {
    ChangeDetectorRef,
    Component,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { BhNavigationItem } from '@bh/types';
import { BhNavigationService } from '@bh/components/navigation/navigation.service';
import { ProfileService } from 'app/services/profile.service';

@Component({
    selector: 'bh-nav-vertical-group',
    templateUrl: './group.component.html',
    styleUrls: ['./group.component.scss'],
})
export class BhNavVerticalGroupComponent implements OnInit, OnDestroy {
    @HostBinding('class')
    classes = 'nav-group nav-item';

    @Input()
    item: BhNavigationItem;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     */

    /**
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {BhNavigationService} _bhNavigationService
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _bhNavigationService: BhNavigationService,
        public ps: ProfileService
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to navigation item
        merge(
            this._bhNavigationService.onNavigationItemAdded,
            this._bhNavigationService.onNavigationItemUpdated,
            this._bhNavigationService.onNavigationItemRemoved
        )
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
