import { NgModule } from '@angular/core';

import { BhHighlightComponent } from '@bh/components/highlight/highlight.component';

@NgModule({
    declarations: [
        BhHighlightComponent
    ],
    exports: [
        BhHighlightComponent
    ],
})
export class BhHighlightModule
{
}
