import {
    Component,
    EventEmitter,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { ComsService } from '@bh/components/coms/coms.service';
import { BhSidebarService } from '@bh/components/sidebar/sidebar.service';
import { ProfileService } from 'app/services/profile.service';
import { DataStore } from 'aws-amplify';
import { Notification } from 'models';
import { Observable } from 'rxjs';

@Component({
    selector: 'quick-panel',
    templateUrl: './quick-panel.component.html',
    styleUrls: ['./quick-panel.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class QuickPanelComponent implements OnInit {
    @Output() notificationCount = new EventEmitter<number>();
    showPopup = true;
    notifications: Notification[] = [];
    /**
     * Constructor
     */
    constructor(
        private _bhSidebarService: BhSidebarService,
        private ps: ProfileService,
        private router: Router,
        private coms: ComsService
    ) {}

    async ngOnInit() {
        await this.getNotifications();
        setInterval(() => {
            this.showPopup = true;
        }, 30000);
    }

    // async subscribeToNewMessages() {
    //     DataStore.observe(Notification, (n) =>
    //         n.userId('eq', this.ps.profile.id).isRead('eq', false)
    //     ).subscribe((res) => {
    //         if (res.opType === 'INSERT') {

    //         }
    //     });
    // }

    async getNotifications() {
        DataStore.observeQuery(Notification, (n) =>
            n.userId('eq', this.ps.profile.id).isRead('eq', false)
        ).subscribe((res) => {
            if (
                this.showPopup &&
                this.notifications.length > 0 &&
                this.notifications.length <= res.items.length
            ) {
                this.coms.showBottomMsg(
                    `You have ${res.items.length} unread notifications`,
                    'info'
                );
                this._bhSidebarService.getSidebar('quickPanel').open();
                this.showPopup = false;
            }
            this.notifications = res.items;
            this.notificationCount.emit(this.notifications.length);
        });
    }

    close() {
        this._bhSidebarService.getSidebar('quickPanel').close();
    }

    async goTo(notification: Notification) {
        await DataStore.save(
            Notification.copyOf(notification, (item) => {
                item.isRead = true;
            })
        );
        this.getNotifications();
        this.router.navigate([notification.link]);
        this.close();
    }
}
