import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../pages/auth/auth.service';
import { tap } from 'rxjs/internal/operators';
import { DataStore } from 'aws-amplify';
import { UserRole } from 'models';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private auth: AuthService) {}
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.auth.isAuthenticated().pipe(
            tap((loggedIn) => {
                if (!loggedIn) {
                    this.router.navigateByUrl('/auth/login');
                }

                if (this.auth.cognitoRole === UserRole.MOBILE) {
                    this.auth.signOutMobileUser();
                }
            })
        );
    }
}

@Injectable({
    providedIn: 'root',
})
export class GuestGuard implements CanActivate {
    constructor(private router: Router, private auth: AuthService) {}
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.auth.isUnAuthenticated().pipe(
            tap((loggedOut) => {
                if (!loggedOut) {
                    this.router.navigateByUrl('/');
                }
            })
        );
    }
}
