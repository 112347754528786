import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, GuestGuard } from './guards/auth.guard';
import { ProfileResolverResolver } from './resolver/profile-resolver.resolver';

const appRoutes: Routes = [
    {
        path: 'home',
        loadChildren: () =>
            import('./pages/home/home.module').then((m) => m.HomeModule),
        canActivate: [AuthGuard],
        resolve: { profile: ProfileResolverResolver },
        data: {
            pageTitle: 'HOME',
        },
    },
    {
        path: 'auth',
        loadChildren: () =>
            import('./pages/auth/auth.module').then((m) => m.AuthModule),
        canActivate: [GuestGuard],
    },
    {
        path: 'elements',
        loadChildren: () =>
            import('./pages/elements/elements.module').then(
                (m) => m.ElementsModule
            ),
        canActivate: [AuthGuard],
        resolve: { profile: ProfileResolverResolver },

        data: {
            pageTitle: 'ELEMENTS',
        },
    },

    {
        path: 'users',
        loadChildren: () =>
            import('./pages/users/users.module').then((m) => m.UsersModule),
        canActivate: [AuthGuard],
        resolve: { profile: ProfileResolverResolver },

        data: {
            pageTitle: 'USERS',
        },
    },
    {
        path: 'my-profile',
        loadChildren: () =>
            import('./pages/my-profile/my-profile.module').then(
                (m) => m.MyProfileModule
            ),
        canActivate: [AuthGuard],
        resolve: { profile: ProfileResolverResolver },

        data: {
            pageTitle: 'MY PROFILE',
        },
    },
    {
        path: 'warehouses',
        loadChildren: () =>
            import('./pages/warehouses/warehouses.module').then(
                (m) => m.WarehousesModule
            ),
        canActivate: [AuthGuard],
        resolve: { profile: ProfileResolverResolver },

        data: {
            pageTitle: 'WAREHOUSES',
        },
    },
    {
        path: 'brands',
        loadChildren: () =>
            import('./pages/brands/brands.module').then((m) => m.BrandsModule),
        canActivate: [AuthGuard],
        resolve: { profile: ProfileResolverResolver },

        data: {
            pageTitle: 'BRANDS',
        },
    },
    {
        path: 'import',
        loadChildren: () =>
            import('./pages/customers/import/import.module').then(
                (m) => m.ImportModule
            ),
        canActivate: [AuthGuard],
        resolve: { profile: ProfileResolverResolver },

        data: {
            pageTitle: 'IMPORT DATA',
        },
    },
    {
        path: 'customers',
        loadChildren: () =>
            import('./pages/customers/customers.module').then(
                (m) => m.CustomersModule
            ),
        canActivate: [AuthGuard],
        resolve: { profile: ProfileResolverResolver },

        data: {
            pageTitle: 'CUSTOMERS',
        },
    },
    {
        path: 'tasks',
        loadChildren: () =>
            import('./pages/tasks/tasks.module').then((m) => m.TasksModule),
        canActivate: [AuthGuard],
        resolve: { profile: ProfileResolverResolver },

        data: {
            pageTitle: 'ALL TASKS',
        },
    },
    {
        path: 'transfers',
        loadChildren: () =>
            import('./pages/transfers/transfers.module').then(
                (m) => m.TransfersModule
            ),
        canActivate: [AuthGuard],
        resolve: { profile: ProfileResolverResolver },
        data: {
            pageTitle: 'STOCK TRANSFERS',
        },
    },

    {
        path: 'new-task',
        loadChildren: () =>
            import('./pages/tasks/new-task/new-task.module').then(
                (m) => m.NewTaskModule
            ),
        canActivate: [AuthGuard],
        resolve: { profile: ProfileResolverResolver },

        data: {
            pageTitle: 'NEW TASK',
        },
    },
    {
        path: 'my-tasks',
        loadChildren: () =>
            import('./pages/tasks/my-tasks/my-tasks.module').then(
                (m) => m.MyTasksModule
            ),
        canActivate: [AuthGuard],
        resolve: { profile: ProfileResolverResolver },

        data: {
            pageTitle: 'MY TASKS',
        },
    },
    {
        path: 'settings',
        loadChildren: () =>
            import('./pages/settings/settings.module').then(
                (m) => m.SettingsModule
            ),
        canActivate: [AuthGuard],
        resolve: { profile: ProfileResolverResolver },

        data: {
            pageTitle: 'SETTINGS',
        },
    },
    {
        path: 'stock',
        loadChildren: () =>
            import('./pages/stock/stock.module').then((m) => m.StockModule),
        canActivate: [AuthGuard],
        resolve: { profile: ProfileResolverResolver },

        data: {
            pageTitle: 'STOCK REPORTS',
        },
    },
    {
        path: 'third-parties',
        loadChildren: () =>
            import('./pages/third-parties/third-parties.module').then(
                (m) => m.ThirdPartiesModule
            ),
        canActivate: [AuthGuard],
        resolve: { profile: ProfileResolverResolver },
        data: {
            pageTitle: 'THIRD PARTIES',
        },
    },
    {
        path: 'sync',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./pages/sync/sync.module').then((m) => m.SyncModule),
    },
    {
        path: 'task-reports',
        loadChildren: () =>
            import('./pages/tasks/reports/reports.module').then(
                (m) => m.ReportsModule
            ),
        canActivate: [AuthGuard],
        resolve: { profile: ProfileResolverResolver },
        data: {
            pageTitle: 'TASK REPORTS',
        },
    },

    {
        path: 'mobile-users',
        loadChildren: () =>
            import('./pages/mobile-users/mobile-users.module').then(
                (m) => m.MobileUsersModule
            ),
    },
    {
        path: 'merge',
        loadChildren: () =>
            import('./pages/admin/merge-elements/merge-elements.module').then(
                (m) => m.MergeElementsModule
            ),
        canActivate: [AuthGuard],
        resolve: { profile: ProfileResolverResolver },
        data: {
            pageTitle: 'MERGE ELEMENTS',
        },
    },

    {
        path: '**',
        redirectTo: 'home',
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' }),
    ],
    exports: [],
    declarations: [],
    providers: [],
})
export class AppRoutingModule {}
