import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ComsModule } from '@bh/components/coms/coms.module';

import { BhSharedModule } from '@bh/shared.module';

import { QuickPanelComponent } from 'app/layout/components/quick-panel/quick-panel.component';

@NgModule({
    declarations: [QuickPanelComponent],
    imports: [
        MatDividerModule,
        MatListModule,
        MatSlideToggleModule,
        MatButtonModule,
        MatIconModule,
        BhSharedModule,
        ComsModule,
    ],
    exports: [QuickPanelComponent],
})
export class QuickPanelModule {}
