import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ProfileComponent } from "./profile.component";
import { MatMenuModule } from "@angular/material/menu";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatDividerModule } from "@angular/material/divider";

@NgModule({
    declarations: [ProfileComponent],
    imports: [
        CommonModule,
        MatMenuModule,
        FlexLayoutModule,
        MatButtonModule,
        MatIconModule,
        MatDividerModule,
    ],
    exports: [ProfileComponent],
})
export class ProfileModule {}
