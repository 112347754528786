import { NgModule } from '@angular/core';

import { BhSidebarComponent } from './sidebar.component';

@NgModule({
    declarations: [
        BhSidebarComponent
    ],
    exports     : [
        BhSidebarComponent
    ]
})
export class BhSidebarModule
{
}
