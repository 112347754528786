<h1 matDialogTitle>Confirm</h1>
<div mat-dialog-content>{{ data.message }}</div>
<div mat-dialog-actions class="pt-24">
    <div fxFlex fxLayoutAlign="space-between center">
        <button mat-button (click)="dialogRef.close(false)">Cancel</button>
        <button
            mat-raised-button
            class="mat-accent mr-16"
            (click)="dialogRef.close(true)"
        >
            Confirm
        </button>
    </div>
</div>
