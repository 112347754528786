import { NgModule } from '@angular/core';

import { BhWidgetComponent } from './widget.component';
import { BhWidgetToggleDirective } from './widget-toggle.directive';

@NgModule({
    declarations: [
        BhWidgetComponent,
        BhWidgetToggleDirective
    ],
    exports     : [
        BhWidgetComponent,
        BhWidgetToggleDirective
    ],
})
export class BhWidgetModule
{
}
