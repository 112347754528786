import { Injectable } from "@angular/core";
import { BhConfigService } from "@bh/services/config.service";

@Injectable({
    providedIn: "root",
})
export class LayoutService {
    constructor(private configService: BhConfigService) {}

    setEmptyLayout() {
        this.configService.config = {
            // Color themes can be defined in src/app/app.theme.scss
            colorTheme: "theme-default",
            customScrollbars: true,
            layout: {
                style: "vertical-layout-1",
                width: "fullwidth",
                navbar: {
                    primaryBackground: "bh-navy-700",
                    secondaryBackground: "bh-navy-700",
                    folded: false,
                    hidden: true,
                    position: "left",
                    variant: "vertical-style-2",
                },
                toolbar: {
                    customBackgroundColor: false,
                    background: "bh-white-500",
                    hidden: true,
                    position: "below-fixed",
                },
                footer: {
                    customBackgroundColor: true,
                    background: "bh-navy-900",
                    hidden: true,
                    position: "below-fixed",
                },
                sidepanel: {
                    hidden: true,
                    position: "right",
                },
            },
        };
    }

    setDefaultLayout(){
        this.configService.resetToDefaults();
    }
}
