import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";

import { BhModule } from "@bh/bh.module";
import { BhSharedModule } from "@bh/shared.module";
import {
    BhConfirmDialogModule,
    BhProgressBarModule,
    BhSidebarModule,
    BhThemeOptionsModule,
} from "@bh/components";

import { bhConfig } from "app/bh-config";

import { AppComponent } from "app/app.component";
import { LayoutModule } from "app/layout/layout.module";
import { AppRoutingModule } from "./app-routing.module";
import { AuthGuard, GuestGuard } from "./guards/auth.guard";
import { ComsModule } from "@bh/components/coms/coms.module";

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,

        AppRoutingModule,
        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,

        // Bh modules
        BhModule.forRoot(bhConfig),
        BhProgressBarModule,
        BhSharedModule,
        BhSidebarModule,
        BhThemeOptionsModule,
        BhConfirmDialogModule,

        // App modules
        LayoutModule,
        ComsModule,
    ],
    bootstrap: [AppComponent],
    providers: [AuthGuard, GuestGuard],
})
export class AppModule {}
