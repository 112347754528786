import { NgModule } from '@angular/core';

import { BhCountdownComponent } from '@bh/components/countdown/countdown.component';

@NgModule({
    declarations: [
        BhCountdownComponent
    ],
    exports: [
        BhCountdownComponent
    ],
})
export class BhCountdownModule
{
}
