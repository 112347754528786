import { BhNavigation } from '@bh/types';
import { UserRole } from 'models';

export const navigation: BhNavigation[] = [
    {
        id: 'dashboard',
        title: 'DASHBOARD',
        type: 'item',
        icon: 'dashboard',
        url: '/home',
        roles: [
            UserRole.ADMIN,
            UserRole.TECH,
            UserRole.REGIONALMANAGER,
            UserRole.PORTALREADONLY,
        ],
    },
    {
        id: 'elements',
        title: 'ELEMENTS',
        type: 'item',
        icon: 'inventory',
        url: '/elements',
        roles: [
            UserRole.ADMIN,
            UserRole.TECH,
            UserRole.REGIONALMANAGER,
            UserRole.PORTALREADONLY,
        ],
    },
    {
        id: 'brands',
        title: 'BRANDS',
        type: 'item',
        icon: 'branding_watermark',
        url: '/brands',
        roles: [
            UserRole.ADMIN,
            UserRole.TECH,
            UserRole.REGIONALMANAGER,
            UserRole.PORTALREADONLY,
        ],
    },
    {
        id: 'transfers',
        title: 'TRANSFERS',
        type: 'item',
        icon: 'move_up',
        url: '/transfers',
        roles: [
            UserRole.ADMIN,
            UserRole.TECH,
            UserRole.REGIONALMANAGER,
            UserRole.PORTALREADONLY,
        ],
    },
    {
        id: 'task-group',
        title: 'TASKS',
        type: 'collapsable',
        icon: 'receipt_long',
        roles: [
            UserRole.ADMIN,
            UserRole.TECH,
            UserRole.REGIONALMANAGER,
            UserRole.PORTALREADONLY,
        ],
        children: [
            {
                id: 'all-tasks',
                title: 'ALL TASKS',
                type: 'item',
                icon: 'fact_check',
                url: '/tasks',
                roles: [
                    UserRole.ADMIN,
                    UserRole.TECH,
                    UserRole.REGIONALMANAGER,
                    UserRole.PORTALREADONLY,
                ],
            },
            {
                id: 'add-task',
                title: 'ADD TASK',
                type: 'item',
                icon: 'add_task',
                url: '/new-task',
                roles: [
                    UserRole.ADMIN,
                    UserRole.TECH,
                    UserRole.REGIONALMANAGER,
                ],
            },
        ],
    },
    {
        id: 'users',
        title: 'USERS',
        icon: 'diversity_3',
        type: 'collapsable',
        roles: [UserRole.ADMIN, UserRole.TECH, UserRole.REGIONALMANAGER],
        children: [
            {
                id: 'users',
                title: 'ALL USERS',
                type: 'item',
                icon: 'diversity_3',
                url: '/users/all',
                roles: [
                    UserRole.ADMIN,
                    UserRole.TECH,
                    UserRole.REGIONALMANAGER,
                ],
            },
            {
                id: 'profile',
                title: 'MY PROFILE',
                type: 'item',
                icon: 'account_circle',
                url: '/my-profile',
                roles: [
                    UserRole.ADMIN,
                    UserRole.TECH,
                    UserRole.REGIONALMANAGER,
                ],
            },
            {
                id: 'createUser',
                title: 'CREATE USER',
                type: 'item',
                icon: 'person_add',
                url: '/users/new',
                roles: [
                    UserRole.ADMIN,
                    UserRole.TECH,
                    UserRole.REGIONALMANAGER,
                ],
            },
        ],
    },
    {
        id: 'thirdPartyAll',
        title: 'THIRD-PARTIES',
        type: 'item',
        icon: 'group_add',
        url: '/third-parties',
        roles: [
            UserRole.ADMIN,
            UserRole.TECH,
            UserRole.REGIONALMANAGER,
            UserRole.PORTALREADONLY,
        ],
    },

    {
        id: 'warehouses',
        title: 'WAREHOUSES',
        type: 'item',
        icon: 'warehouse',
        url: '/warehouses',
        roles: [UserRole.ADMIN, UserRole.TECH, UserRole.REGIONALMANAGER],
    },
    {
        id: 'customers',
        title: 'CUSTOMERS',
        type: 'item',
        icon: 'groups',
        url: '/customers',
        roles: [
            UserRole.ADMIN,
            UserRole.TECH,
            UserRole.REGIONALMANAGER,
            UserRole.PORTALREADONLY,
        ],
    },
    {
        id: 'reports-group',
        title: 'REPORTS',
        type: 'collapsable',
        icon: 'assessment',
        roles: [
            UserRole.ADMIN,
            UserRole.TECH,
            UserRole.REGIONALMANAGER,
            UserRole.PORTALREADONLY,
        ],
        children: [
            {
                id: 'stock-reports',
                title: 'STOCK REPORTS',
                type: 'item',
                icon: 'assessment',
                url: '/stock',
                roles: [
                    UserRole.ADMIN,
                    UserRole.TECH,
                    UserRole.REGIONALMANAGER,
                    UserRole.PORTALREADONLY,
                ],
            },
            {
                id: 'reports',
                title: 'TASK REPORTS',
                type: 'item',
                icon: 'assessment',
                url: '/task-reports',
                roles: [
                    UserRole.ADMIN,
                    UserRole.TECH,
                    UserRole.REGIONALMANAGER,
                    UserRole.PORTALREADONLY,
                ],
            },
        ],
    },

    {
        id: 'admin-group',
        title: 'ADMIN',
        type: 'collapsable',
        icon: 'settings',
        roles: [UserRole.ADMIN, UserRole.TECH, UserRole.REGIONALMANAGER],
        children: [
            {
                id: 'import',
                title: 'IMPORT CUSTOMERS',
                type: 'item',
                icon: 'import_export',
                url: '/import',
                roles: [UserRole.ADMIN, UserRole.TECH, UserRole.REGIONALMANAGER],
            },
            {
                id: 'merge',
                title: 'MERGE ELEMENTS',
                type: 'item',
                icon: 'sync_alt',
                url: '/merge',
                roles: [UserRole.ADMIN, UserRole.TECH, UserRole.REGIONALMANAGER],
            },
            // {
            //     id: 'import-data',
            //     title: 'IMPORT DATA',
            //     type: 'item',
            //     icon: 'import_export',
            //     url: '/import-data',
            //     roles: [UserRole.TECH],
            // },
            // {
            //     id: "settings",
            //     title: "SETTINGS",
            //     type: "item",
            //     icon: "settings",
            //     url: "/settings",
            //     roles: [UserRole.TECH],
            // },
        ],
    },
];
