import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
  /**
   * Truncate pipe to reduce the selected string value
   * @param value - length of string
   * @param limit - limit set to truncate
   * @param completeWords - check for complete words
   * @param ellipsis - display ellipses where string truncated
   * @returns - truncated string
   */
  transform(value: string, limit = 25, completeWords = false, ellipsis = '...') {
    if (!value) {
      return '';
    }
    if (completeWords) {
      limit = value.substr(0, limit).lastIndexOf(' ');
    }
    return value.length > limit ? value.substr(0, limit) + ellipsis : value;
  }
}
