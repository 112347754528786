import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';

import { BhNavigationComponent } from './navigation.component';
import { BhNavVerticalItemComponent } from './vertical/item/item.component';
import { BhNavVerticalCollapsableComponent } from './vertical/collapsable/collapsable.component';
import { BhNavVerticalGroupComponent } from './vertical/group/group.component';
import { BhNavHorizontalItemComponent } from './horizontal/item/item.component';
import { BhNavHorizontalCollapsableComponent } from './horizontal/collapsable/collapsable.component';

@NgModule({
    imports     : [
        CommonModule,
        RouterModule,

        MatIconModule,
        MatRippleModule,
    ],
    exports     : [
        BhNavigationComponent
    ],
    declarations: [
        BhNavigationComponent,
        BhNavVerticalGroupComponent,
        BhNavVerticalItemComponent,
        BhNavVerticalCollapsableComponent,
        BhNavHorizontalItemComponent,
        BhNavHorizontalCollapsableComponent
    ]
})
export class BhNavigationModule
{
}
