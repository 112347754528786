import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ComsComponent } from './coms.component';

export type MessageType = 'success' | 'warning' | 'error' | 'info';

@Injectable({
    providedIn: 'root',
})
export class ComsService {
    constructor(private snackBar: MatSnackBar) {}

    showMsg(msg, t: MessageType) {
        let color = 'blue-600-bg';
        switch (t) {
            case 'success':
                color = 'green-600-bg';
                break;
            case 'warning':
                color = 'orange-600-bg';
                break;
            case 'error':
                color = 'red-600-bg';
                break;
            case 'info':
                color = 'blue-600-bg';
                break;
        }
        this.snackBar.openFromComponent(ComsComponent, {
            duration: 8000,
            data: {
                msg,
            },
            panelClass: [color, 'message-box'],
            horizontalPosition: 'right',
            verticalPosition: 'top',
        });
    }

    showBottomMsg(msg, t: MessageType) {
        let color = 'blue-600-bg';
        switch (t) {
            case 'success':
                color = 'green-600-bg';
                break;
            case 'warning':
                color = 'orange-600-bg';
                break;
            case 'error':
                color = 'red-600-bg';
                break;
            case 'info':
                color = 'blue-600-bg';
                break;
        }
        this.snackBar.openFromComponent(ComsComponent, {
            duration: 12000,
            data: {
                msg,
            },
            panelClass: [color, 'message-box'],
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
        });
    }
}
