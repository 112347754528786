import {
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { delay, filter, take, takeUntil } from 'rxjs/operators';

import { BhConfigService } from '@bh/services/config.service';
import { BhNavigationService } from '@bh/components/navigation/navigation.service';
import { BhPerfectScrollbarDirective } from '@bh/directives/bh-perfect-scrollbar/bh-perfect-scrollbar.directive';
import { BhSidebarService } from '@bh/components/sidebar/sidebar.service';
import { environment } from 'environments/environment';

@Component({
    selector: 'navbar-vertical-style-2',
    templateUrl: './style-2.component.html',
    styleUrls: ['./style-2.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class NavbarVerticalStyle2Component implements OnInit, OnDestroy {
    bhConfig: any;
    navigation: any;
    sidebarOpened: boolean;
    version = environment.appVersion;
    // Private
    private _bhPerfectScrollbar: BhPerfectScrollbarDirective;
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {BhConfigService} _bhConfigService
     * @param {BhNavigationService} _bhNavigationService
     * @param {BhSidebarService} _bhSidebarService
     * @param {Router} _router
     */
    constructor(
        private _bhConfigService: BhConfigService,
        private _bhNavigationService: BhNavigationService,
        private _bhSidebarService: BhSidebarService,
        private _router: Router
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    // Directive
    @ViewChild(BhPerfectScrollbarDirective, { static: true })
    set directive(theDirective: BhPerfectScrollbarDirective) {
        if (!theDirective) {
            return;
        }

        this._bhPerfectScrollbar = theDirective;

        // Update the scrollbar on collapsable item toggle
        this._bhNavigationService.onItemCollapseToggled
            .pipe(delay(500), takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this._bhPerfectScrollbar.update();
            });

        // Scroll to the active item position
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                take(1)
            )
            .subscribe(() => {
                setTimeout(() => {
                    this._bhPerfectScrollbar.scrollToElement(
                        'navbar .nav-link.active',
                        -120
                    );
                });
            });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                if (this._bhSidebarService.getSidebar('navbar')) {
                    this._bhSidebarService.getSidebar('navbar').close();
                }
            });

        // Get current navigation
        this._bhNavigationService.onNavigationChanged
            .pipe(
                filter((value) => value !== null),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                this.navigation =
                    this._bhNavigationService.getCurrentNavigation();
            });

        // Subscribe to the config changes
        this._bhConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.bhConfig = config;
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar opened status
     */
    toggleSidebarOpened(): void {
        this._bhSidebarService.getSidebar('navbar').toggleOpen();
    }

    /**
     * Toggle sidebar folded status
     */
    toggleSidebarFolded(): void {
        this._bhSidebarService.getSidebar('navbar').toggleFold();
    }
}
