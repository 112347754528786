import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';

import { BH_CONFIG } from '@bh/services/config.service';

@NgModule()
export class BhModule
{
    constructor(@Optional() @SkipSelf() parentModule: BhModule)
    {
        if ( parentModule )
        {
            throw new Error('BhModule is already loaded. Import it in the AppModule only!');
        }
    }

    static forRoot(config): ModuleWithProviders<BhModule>
    {
        return {
            ngModule : BhModule,
            providers: [
                {
                    provide : BH_CONFIG,
                    useValue: config
                }
            ]
        };
    }
}
