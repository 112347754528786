import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WarehouseSwitcherComponent } from './warehouse-switcher.component';
import { BaseModule } from '@bh/shared/base.module';

@NgModule({
    declarations: [WarehouseSwitcherComponent],
    imports: [CommonModule, BaseModule],
    exports: [WarehouseSwitcherComponent],
})
export class WarehouseSwitcherModule {}
