import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatToolbarModule } from "@angular/material/toolbar";

import { BhSearchBarModule, BhShortcutsModule } from "@bh/components";
import { BhSharedModule } from "@bh/shared.module";

import { ToolbarComponent } from "app/layout/components/toolbar/toolbar.component";
import { MatDividerModule } from "@angular/material/divider";
import { ProfileModule } from "../profile/profile.module";
import {MatBadgeModule} from '@angular/material/badge';
import { WarehouseSwitcherModule } from '../warehouse-switcher/warehouse-switcher.module';

@NgModule({
    declarations: [ToolbarComponent],
    imports: [
        RouterModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatToolbarModule,
        MatDividerModule,

        BhSharedModule,
        BhSearchBarModule,
        BhShortcutsModule,
        ProfileModule,
        MatBadgeModule,
        WarehouseSwitcherModule
    ],
    exports: [ToolbarComponent],
})
export class ToolbarModule {}
